import React from 'react';
import Layout from "../../components/Layout/Layout"
import { Link } from "gatsby";
import Seo from '../../components/Seo/Seo';
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  h1 {
    font-size: 14vw;
    line-height: normal;
    margin: 0;
    text-align: center;
    @media (min-width: 1800px) {
        font-size: 260px;
    }
    @media screen and (max-width: 550px) {
        font-size: 62px;
    }
  }
    p {
        font-weight: 600;
        font-size: 3vw;
        @media (min-width: 1800px) {
            font-size: 44px;
        }
        @media screen and (max-width: 550px) {
            font-size: 21px;
        }
    }
    .btn {
        margin-top: 1rem;
    }
`

const TyPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <div className="container">
        <Wrapper>
            <h1>THANK YOU</h1>
            <p>Thank you for contacting Red Lab! A member from our team will reach out shortly.</p>
            <Link to="/" className="btn">
                <span>Return to Homepage</span>
            </Link>
        </Wrapper>
    </div>
  </Layout>
)


export default TyPage;
